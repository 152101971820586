import './App.css';
import { Routes, Route } from "react-router-dom";
import TagManager from 'react-gtm-module'
import ContactUs from "./ContactUs"
import Rules from "./Rules"
import Home from "./Home"
import Book from "./Book"
import Footer from "./Footer"

const tagManagerArgs = {
  gtmId: 'AW-11404852647'
}

TagManager.initialize(tagManagerArgs)


function App() {
  return (
    <>
    <div className='app-container'>
      <div className="header">
        <NavHead/>
      </div>
      <div className="content">
        <Routes >
              <Route path="/" element={<Home />} />
                <Route path="Book" element={<Book />} />
                <Route path="Rules" element={<Rules />} />
                <Route path="ContactUs" element={<ContactUs />} />
        </Routes>
      </div>
      <Footer/>
    </div>
    </>
  );
}

function NavHead() {
  const uriPath = window.location.pathname;
  return (
    <div className='topnav' style={{  position: 'top',  width: '100%' }}>
      <a className={uriPath === "/" ? "active" : ""} href="/">
        Hem
      </a>
      <a className={uriPath === "/Book" ? "active" : ""} href="Book">
        Boka
      </a>
      <a className={uriPath === "/Rules" ? "active" : ""} href="Rules">
        Regler
      </a>
      <a className={uriPath === "/ContactUs" ? "active" : ""} href="ContactUs">
        Kontakt
      </a>
    </div>
  );
}

export default App;
