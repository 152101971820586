import './App.css';
import secondImage from './images/insideBeerAndBucket_wide.jpg';
import firstImage from './images/OutsideParking.jpg';
import inside from './images/insideCozy.jpg';
function Home() {
  return (
    <div className="home">
      <header>
        <h1>Välkommen till Bastuimperiet</h1>
        <img
          src={firstImage}
          alt="sauna"
          className="responsive-image"
        />
      </header>

      <section className="om-oss">
        <h2>Om Oss</h2>
        <p>
        På Bastuimperiet erbjuder vi bastuupplevelser för allt mellan
 svensexa och afterwork till lugna stunder tillsammans med dina nära och kära. 
 
 
        </p>
      </section>
      <section className="image">
        <img
          src={inside}
          alt="Sauna interior"
          className="responsive-image"
        />
      </section>
      <section className="om-oss">
        <h2>Vägen till avkoppling</h2>
        <p>
        Vår bastu är ett flexibelt sätt att njuta av ett bastubad på din utvalda plats oavsett om det är vid sjön, ditt favoritställe eller garageuppfarten.
        <br/>
        Vägen till avkoppling går enkelt via vår mobila bastu. 
        </p>
      </section>

      <section className="bild">
      <img
          src={secondImage}
          alt="sauna"
          className="responsive-image"
        />
      </section>

      <section className="cta">
        <h2>Boka din bastustund idag</h2>
        <p>Njut
 av höstens magi med värme från en sprakande eld och samvaro med dem du tycker om.</p>
        <a href="/Book" className="btn btn-primary">Boka Nu!</a>
      </section>

      <section className="geografiskt-omrade">
        <h2>Geografiskt område</h2>
        <p>Vår mobila bastu är baserad i Kil, Värmland, men tack vare bastuvagnens mobilitet kan du enkelt
        <br/>transportera den oavsett var du befinner dig i regionen. Vi servar inte bara Kil,
        <br/>utan även orter som exempelvis Karlstad, Hammarö, Alster, Skattkärr, Kristinehamn, Forshaga,
        <br/>Fagerås, Arvika, Sunne, Skattkärr, Segerstad, Grums, Molkom, Deje och Högboda.</p>
        <p>Tack var flexibiliteten med en mobil bastu på en bromsad släpvagn (maxhastighet 80 km/h) kan
        <br/>den även tas längre sträckor, vilket gör det enkelt för dig att njuta av en bastuupplevelse
        <br/>var du än befinner dig i Värmland med omnejd.
        </p>
        <p>För priser (med och utan utkörning), se <a href="/Book" className="btn btn-primary">bokningssidan</a>.</p>
      </section>

    </div>
  );
};


export default Home;
