// Footer.js
import React from 'react';
import './App.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-row">
                <div className="footer-column">Bastuimperiet Ingvar AB</div>
                <div className="footer-column">Organisationsnummer 559407-3867</div>
            </div>
        </footer>
    );
}

export default Footer;
