import './App.css';

function ContactUs() {
  const pStyle = {
    margin: '0px 0px', // You can adjust the margin as needed
  };

  return (
    <div>
      <h2>Kontaktuppgifter</h2>
      <section style={{ textAlign: 'center' }}>
        <div>
          <p style={pStyle}>Jacob Östelid: 0760503418</p>
          <p style={pStyle}>Johannes Larsson: 0704019011</p>
          <p style={pStyle}><a href="mailto:bastuimperiet@gmail.com">bastuimperiet@gmail.com</a></p>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;
