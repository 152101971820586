import './App.css';
import React from 'react';


const Rules = () => {
  const listStyle = {
    listStyleType: 'decimal', // Use 'decimal' for numbering
    textAlign: 'left', // Align the list items to the left
    paddingLeft: '20px', // Add some left padding for better alignment
  };

  return (
    <div className="rules">
      <header>
        <h1>Regler Bastubod</h1>
      </header>

      <section className="generellt">
        <h2>Generell information</h2>
        <p>
          Kontrollera att tilltänkta föraren och det<br/>
          tillänkta dragfordonet utgör en laglig kombination.<br/>
          Ange körkortsbehörighet samt registreringsnummer för dragfordon och släp på<br/>
          <a href="https://slapvagnskalkylatorn.transportstyrelsen.se/Slapvagnskalkylator/Slapvagnskalkylator.aspx" target="_blank" rel="noopener noreferrer">Transportstyrelsens Släpvagnskalkylator</a><br/><br/>
          Släpets registreringsnummer är <b>DET15N</b>, totalvikten är 1300 kg<br/>
          och släpet med boden på, bruttovikten, är 1250 kg.<br/>
          Bastubodens höjd är 370 cm.
        </p>
      </section>

      <section className="sakerhet">
        <h2>Säkerhet</h2>
        <ul style={listStyle}>
          <li>Hoppa inte i bastun.</li>
          <li>Beträd inte taket.</li>
          <li>Alkoholförbud råder inte, men se till att du/ni kan ta hand om er. En kamin kan orsaka allvarliga brännskador.</li>
          <li>Öppna båda ventilerna vid eldning.</li>
          <li>Blockera <b>aldrig</b> dörren.</li>
          <li>Vidrör aldrig rökröret eller kaminen.</li>
          <li>Inga brandfarliga vätskor eller föremål får finnas i bastun.</li>
          <li>Kasta <b>aldrig</b> något annat än rent vatten på stenarna/kaminen. Urin, alkoholhaltiga drycker, läsk osv kräver omfattande sanering och kommer att debiteras kund.</li>
          <li>Sov <b>aldrig</b> i bastun.</li>
          <li>Mat får inte tillagas på kaminen.</li>
          <li>Observera att lavarna kan fälla ut kåda.</li>
        </ul>
      </section>

      <section className="transport">
        <h2>Transport</h2>
        <ul style={listStyle}>
          <li>Bastuimperiet ansvarar INTE för skador som uppstår under transport eller användning av bastubod. Dragbilen måste vara minst trafikförsäkrad.</li>
          <li>Kör inte i storm eller kraftigt oväder.</li>
          <li>Bastun får inte flyttas innan kaminen har svalnat helt.</li>
          <li>Ta bort alla pallbockar innan släpvagnen förflyttas.</li>
          <li>Lägg tillbaka alla saker i lådan (hink/slev, lyktor, pallbockar osv) och ställ in lådan i hörnet av lavarna. Dra det långa spännbandet runt lådan och dra fast den så att den inte flyttar sig under färd.</li>
          <li>Montera stegen på golvet närmast dörren. Spänn fast med det kortare spännbandet.</li>
          <li>Ekipaget är högt. Var därför observant på låga underfarter så att den inte tar i exempelvis låg bro.</li>
          <li>Bastun får dras i max 80 km/h i enlighet med gällande svenska regler. Detta gäller även vid omkörning och påfart på motorväg. OBS! Bastuboden är inte konstruerad för högre hastigheter än 80 km/h och riskerar därför att skadas vid högre hastigheter.</li>
          <li>Accelerera mjukt så att inte kamin eller annat skadas.</li>
          <li>Ekipaget får ej lämna Sverige.</li>
          <li>Kontrollera att parkeringsbromsen är urkopplad före färd.</li>
          <li>Fastgör kaminluckan med ståltråden innan ekipaget förflyttas.</li>
          <li>Kontrollera att ljuset fungerar innan avfärd.</li>
          <li>Det är inte tillåtet att förvara något i bastun vid färd såsom dryck, kläder, mat.</li>
          <li>Det är strängt förbjudet att vistas i bastun vid färd.</li>
          <li>Du som förare ansvarar för att kontrollera att ditt dragfordon får dra släpvagnen och att du har rätt körkortsbehörighet.</li>
        </ul>
      </section>

      <section className="uppstallning">
        <h2>Uppställning</h2>
        <ul style={listStyle}>
          <li>Parkera inte i sluttning/lutning, se till att bastun står plant.</li>
          <li>Placera alla åtta pallbockarna under bastun innan du kopplar bort släpvagnen.</li>
          <li>De sex pallbockarna med rör högst upp ska sättas ihop med de balkskor som sitter på golvreglarna. De två återstående pallbockarna placeras i mitten längst fram och längst bak på släpet.</li>
          <li>Parkera alltid på fast mark. Se till att pallbockarna står stadigt på marken och att rören som håller uppe boden är raka.</li>
          <li>Håll ordentligt avstånd mellan bastun och andra objekt, exempelvis byggnader, fordon, träd eller annan hög vegetation.</li>
          <li>Det ska vara helt fritt ovanför bastun, ställ aldrig upp den under träd eller annat.</li>
          <li>Kontrollera att parkeringsbromsen är åtdragen vid uppställning.</li>
          <li>Ta loss stege och låda med tillbehör.</li>
          <li>Lyktorna ska stå på golvet i bastun, långt från kaminen, för att skyddas från värme och ånga.</li>
          <li>Fyll stävan (hinken) med vatten och placera nära kaminen. Kasta bad (ös vatten) på den heta kaminens stenar för att skapa hög luftfuktighet.</li>
          <li>Bastuboden bör förvaras lutande när den inte används, det vill säga genom att höja stödhjulet fram. Detta gör att vatten och snö lättare rinner av.</li>
          <li>Om stora mängder snö faller på kort tid bör taket skottas av i den mån det är möjligt för att inte orsaka att taket rasar in av vikten (utan att beträda taket i enlighet med tidigare regel).</li>
        </ul>
      </section>
    </div>
  );
};

export default Rules;
